<template>
  <ion-grid>
    <ion-row>
      <ion-col>
        <you-are-viewing-line :line="state.line" :layout="state.layout"></you-are-viewing-line>
        <ion-button v-if="state.layout === 'mobile'" v-on:click="clear()" size="small" color="primary" expand="block" class="mkc-button mkc-small mkc-back">
          <span><ion-icon :icon="chevronBackCircle"></ion-icon>&nbsp;Choose another crimp line</span>
        </ion-button>
        <template v-if="state.showHelp">
          <p v-if="getSelectedItem('coupling-type')">
            <HelpText :text="'category_view_specs'"></HelpText>
          </p>
          <p v-else-if="!getSelectedItem('hose-type')">
            <HelpText :text="'category_intro'"></HelpText>
          </p>
          <p v-else>
            <HelpText :text="'category_continue'"></HelpText>
          </p>
        </template>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <category-button
            label="Hose Type"
            image-url="/assets/img/hose.svg"
            category-slug="hose-type"
            :selected-item="getSelectedItem('hose-type')"
            :disabled="!!getSelectedItem('hose-type')"
            :available-count="hoseType.count"
            @click="setCategory"></category-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <category-button
            label="Hose Size"
            image-url="/assets/img/ruler.svg"
            category-slug="hose-size"
            :selected-item="getSelectedItem('hose-size')"
            :disabled="!getSelectedItem('hose-type')
             || (!!getSelectedItem('hose-size') && !!getSelectedItem('hose-type'))"
            :available-count="hoseSize.count"
            @click="setCategory"></category-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <category-button
            label="Coupling Type"
            image-url="/assets/img/coupling.svg"
            category-slug="coupling-type"
            :selected-item="getSelectedItem('coupling-type')"
            :disabled="!getSelectedItem('hose-size')
            || (!!getSelectedItem('hose-size') && !!getSelectedItem('hose-type') && !!getSelectedItem('coupling-type'))"
            :available-count="couplingType.count"
            @click="setCategory"></category-button>
      </ion-col>
    </ion-row>
    <ion-row v-if="showHoseWallThickness">
      <ion-col>
        <category-button
            label="Hose Wall Thickness"
            category-slug="hose-wall-thickness"
            :selected-item="getSelectedItem('hose-wall-thickness')"
            :disabled="!haveCrimpSpecs"
            :available-count="hoseWallThickness.count"
            @click="setCategory"></category-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button
            v-if="(getSelectedItem('coupling-type') && !showHoseWallThickness) || showHoseWallThickness && (getRecords.length === 1)"
            size="large"
            expand="block"
            @click="openModal"
            class="mkc-button mkc-view-crimp-data">
          View Crimp Data
          <ion-icon slot="end" :icon="chevronForwardCircle" color="light"/>
        </ion-button>
        <!--T604AA
        TR4XF
        <template v-if="getRecords.length > 1">
          <div v-for="(record, i) in getRecords" :key="record" class="crimp-spec" :id="'crimp-spec-' + i">
            {{ record['Hose Wall Thickness'] }}
          </div>
        </template>-->
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button @click="clearParametersForCurrentLine"
                    size="default"
                    expand="block"
                    color="secondary"
                    fill="outline"
                    class="mkc-clear-search-params">Clear
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {IonButton, IonGrid, IonRow, IonCol, IonIcon, modalController} from '@ionic/vue'
import store from '@/store'
import {chevronBackCircle, chevronForwardCircle, chevronForwardOutline} from "ionicons/icons";
import CrimpData from "@/components/CrimpData";
import HelpText from "@/components/HelpText";
import CategoryButton from "@/components/CategoryButton";
import YouAreViewingLine from "@/components/YouAreViewingLine";

export default {
  name: "ChooseCategory",
  components: {IonButton, IonGrid, IonRow, IonCol, IonIcon, HelpText, CategoryButton, YouAreViewingLine},
  emits: ['itemSelected'],
  setup() {
    return {
      chevronBackCircle,
      chevronForwardCircle,
      chevronForwardOutline
    }
  },
  data() {
    return {
      state: store.state,
      hoseType: {count: false, selected: ''},
      hoseSize: {count: false, selected: ''},
      couplingType: {count: false, selected: ''},
      hoseWallThickness: {count: false, selected: ''}
    }
  },
  async created() {
    await this.getAllCategoryCounts()
  },
  methods: {
    clear() {
      store.clearParameters();
    },
    setCategory(category) {
      store.setCategory(category);
      this.$emit('itemSelected');
    },
    async getAllCategoryCounts() {
      //if (!this.state.filteredCollection) {
      await store.getFilteredRecords().then(async () => {
        this.hoseType.count = await this.getCategoryCount('hose-type');
        this.hoseSize.count = await this.getCategoryCount('hose-size');
        this.couplingType.count = await this.getCategoryCount('coupling-type');
        this.hoseWallThickness.count = await this.getCategoryCount('hose-wall-thickness');
      });
      //}
    },
    async getCategoryCount(category) {
      if (!this.state.line) {
        return false;
      }
      if (category === 'hose-wall-thickness') {
        return this.getRecords.length
      }
      if (this.state.filteredCollection[this.state.line] === false
          || this.state.filteredCollection[this.state.line] === undefined
          || Object.keys(this.state.filteredCollection[this.state.line]).length === 0) {
        // Use unfiltered lists
        const collectionName = store.collectionName(this.state.line, store.searchFields[category]);
        const records = await store.fetchCompleteCollection(collectionName)
        //console.log('getCategoryCount ' + category + ' from firebase ' + Object.keys(records).length)
        return Object.keys(records).length;
      } else {
        // We have a filtered list so use it
        const records = await store.getFilteredRecordsByCategory(this.state.line, category);
        //console.log('getCategoryCount ' + category + ' from state ' + Object.keys(records).length)
        return Object.keys(records).length;
      }

    },
    getSelectedItem(category) {
      const selectedItem = store.getSelectedItem(this.state.line, category);
      return selectedItem;
    },
    async openModal() {
      const modal = await modalController
          .create({
            component: CrimpData,
            cssClass: 'crimp-spec',
            componentProps: {
              title: 'Your Crimp Spec'
            },
          })
      return modal.present();
    },
    clearParametersForCurrentLine() {
      store.clearParametersForCurrentLine();
    },
  },
  computed: {
    haveCrimpSpecs() {
      return !!(store.getSelectedItem(this.state.line, 'coupling-type')
          && store.getSelectedItem(this.state.line, 'hose-size')
          && store.getSelectedItem(this.state.line, 'hose-type'))
    },
    getRecords() {
      return (this.haveCrimpSpecs) ? Object.values(this.state.filteredCollection[this.state.line]) : false;
    },
    showHoseWallThickness() {
      const hoseType = store.getSelectedItem(this.state.line, 'hose-type');
      if (!hoseType) return false;
      const ret = (['T604AA', 'TR4XF'].find((field) => {
        return hoseType.indexOf(field) !== -1
      }));

      return !!ret;
    }
  }
}
</script>

<style lang="scss" scoped>

.extra-details {
  font-size: 0.5em;
}
</style>
