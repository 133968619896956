<template>
  <p v-if="line && layout === 'mobile' " class="mkc-viewing-line">
    YOU ARE VIEWING <img :src="'/assets/img/'+ line +'-logo.webp'" :alt="line">
  </p>
</template>

<script>
export default {
  name: "YouAreViewingLine",
  props: {
    line: String,
    layout: String
  }
}
</script>

<style scoped>

</style>
