<template>
  <ion-grid>
    <ion-row>
      <ion-col>
        <you-are-viewing-line :line="state.line" :layout="state.layout"></you-are-viewing-line>
        <ion-button v-if="state.layout === 'mobile'" v-on:click="clear()" size="small" color="primary" expand="block" class="mkc-button mkc-small mkc-back">
          <span><ion-icon :icon="chevronBackCircle"></ion-icon>&nbsp;Choose another crimp line</span>
        </ion-button>
        <template v-if="state.showHelp">
          <HelpText :text="'search_' + state.category"></HelpText>
        </template>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="mkc-search-category-heading">
          <img :src="`/assets/img/${state.category}.svg`" alt="Hose Type" class="mkc-search-category-image">
          <span>{{ categoryName }}</span>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-searchbar
          color="light"
            inputmode="search"
            show-clear-button="always"
            debounce="500"
            @ionChange="doSearch();"
            @ionClear="clearSearch()"
            :modelValue="query"
            @update:modelValue="query = $event"
            ref="search"
        ></ion-searchbar>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <template v-for="result in results" :key="result">
          <ion-item button @click="selectItem(result.name)">
            <ion-label>{{ result.name }}</ion-label>
            <ion-icon slot="end" :icon="addCircleOutline" color="primary"/>
          </ion-item>
        </template>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import store from '@/store'
import {IonButton, IonCol, IonGrid, IonIcon, IonRow, IonSearchbar, IonItem, IonLabel} from '@ionic/vue'
import {chevronBackCircle, addCircleOutline} from "ionicons/icons";
import HelpText from "@/components/HelpText";
import YouAreViewingLine from "@/components/YouAreViewingLine";


export default {
  name: "ProductSearch",
  components: {IonButton, IonGrid, IonRow, IonCol, IonIcon, IonSearchbar, IonItem, IonLabel, HelpText, YouAreViewingLine},
  emits: ['itemSelected'],
  setup() {
    return {
      chevronBackCircle,
      addCircleOutline
    }
  },
  created() {
    this.initSearch();
    setTimeout(() => this.$refs.search.$el.setFocus(), 300)
  },
  updated() {
    console.log(this.query);
  },
  data() {
    return {
      query: '',
      results: [],
      initResults: [],
      state: store.state
    }
  },
  methods: {
    clear() {
      store.clearParameters();
    },
    clearSearch() {
      this.initSearch();
    },
    async initSearch() {
      this.query = '';
      //console.log(this.state.category, this.state.filteredCollection[this.state.line], this.state.filteredCollection[this.state.line].length);
      if (store.searchFields[this.state.category]) {
        if (undefined === this.state.filteredCollection[this.state.line]
            || false === this.state.filteredCollection[this.state.line]
            || Object.keys(this.state.filteredCollection[this.state.line]).length === 0
        ) {
          // IF we don't have a filtered collection THEN fetch from firebase
          this.initResults = await store.fetchFilteredRecords(this.state.line);
        } else {
          // Fetch from local filtered collection
          this.initResults = await store.getFilteredRecordsByCategory(this.state.line, this.state.category);
        }
        this.results = this.initResults;
      } else {
        console.log('collection not found')
      }
    },
    async doSearch() {
      this.results = Object.freeze(Object.values(this.initResults).filter((result) => String(result.name).toUpperCase().indexOf(this.query.toUpperCase()) > -1));
    },
    selectItem(name) {
      store.setSelectedItem(this.state.line, this.state.category, name);
      this.$emit('itemSelected');
    },
  },
  computed: {
    categoryName() {
      return store.categoryLabels[this.state.category]
    }
  }
}
</script>

<style scoped>
</style>
