<template>
  <ion-page>
    <Header></Header>
    <ion-content>

      <ChooseLine v-if="state.layout === 'desktop' && !state.line" v-on:itemSelected="forceRerender"></ChooseLine>
      <template v-if="state.layout === 'mobile'">
        <ChooseLine v-if="!state.line" v-on:itemSelected="forceRerender"></ChooseLine>
        <ChooseCategory v-if="state.line && !state.category" :key="componentKey"></ChooseCategory>
        <ProductSearch v-if="state.line && state.category" v-on:itemSelected="forceRerender"></ProductSearch>
      </template>
      <template v-else>
        <template v-if="state.line">
        <IonGrid>
          <IonRow>
            <IonCol size="3">
              <ChooseLine v-on:itemSelected="forceRerender" :showStacked="true"></ChooseLine>
            </IonCol>
            <IonCol size="3">
              <ChooseCategory :key="componentKey"  v-on:itemSelected="forceRerender"></ChooseCategory>
            </IonCol>
            <IonCol size="6">
              <ProductSearch :key="componentKey"  v-if="state.line && state.category"  v-on:itemSelected="forceRerender"></ProductSearch>
            </IonCol>
          </IonRow>
        </IonGrid>
        </template>
      </template>
    </ion-content>
  </ion-page>
</template>

<script lang="js">

import ChooseLine from '@/components/ChooseLine.vue'
import ChooseCategory from '@/components/ChooseCategory.vue'
import ProductSearch from '@/components/ProductSearch.vue'
import Header from "@/components/global/Header";
import {IonPage, IonContent, IonGrid, IonRow, IonCol} from '@ionic/vue';

import store from '@/store'

export default {
  name: 'Base Layout',
  components: {IonContent, IonPage, IonGrid, IonRow, IonCol, ChooseLine, ChooseCategory, ProductSearch, Header},
  data() {
    return {
      state: store.state,
      componentKey: Date.now(),
    }
  },
  methods: {
    forceRerender() {
      // REF: https://michaelnthiessen.com/force-re-render/
      this.componentKey = Date.now();
    }
  }
}
</script>
