<template>
  <button v-on:click="click()"
          class="mkc-category mkc-button"
          :class="'mkc-category-' + categorySlug"
          :disabled="disabled"
  >
    <div class="mkc-button-inner">
      <div class="mkc-name">
        <img :src="`/assets/img/${categorySlug}.svg`" alt="Hose Type">
        <span class="label">{{ label }}</span>
        <div class="icon-wrapper">
          <ion-icon :icon="chevronForwardOutline" color="primary"/>
        </div>
      </div>
      <div class="mkc-extra-details">
        <div class="mkc-available">
          <template  v-if="availableCount">
            <strong>Available:</strong> {{ availableCount }}
          </template>
        </div>
        <div class="mkc-chosen"><strong>Chosen:</strong>
          <template v-if="selectedItem">{{ selectedItem }}</template>
          <template v-else>N/A</template>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import {IonIcon} from '@ionic/vue'
import {chevronForwardOutline} from "ionicons/icons";

export default {
  name: "CategoryButton",
  components: {IonIcon},
  setup() {
    return {chevronForwardOutline}
  },
  props: {
    label: String,
    imageUrl: String,
    categorySlug: String,
    disabled: Boolean,
    selectedItem: [String, Boolean, Number],
    availableCount: [Number, Boolean]
  },
  emits: {
    click: String
  },
  methods: {
    click() {
      this.$emit('click', this.categorySlug)
    }
  }
}
</script>

<style scoped>

</style>
